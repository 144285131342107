
export const config = {
  primaryColor: "#283891",
  appBarColor: "#283891",
  sortBar: "white",
  drawerWidth: 240,
  menuItemColor: "white"
};


