import {
  Card,
  Icon,
  IconButton,
  makeStyles,
  Typography,
  colors,
  Avatar,
  Switch,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import { SizedBox } from "../sizedBox/sizedBox";
import HomeIcon from "@material-ui/icons/Home";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import { IconedText } from "../iconedText/IconedText";
import { useTranslation } from "react-i18next";
import { Clear } from "@material-ui/icons";

export interface UserCardProps {
  width?: number;
  height?: number;
  userActive?: boolean;
  image?: string;
  borderRadius?: number;
  avatarRadius?: number;
  userName: string;
  address: string;
  mobileNumber: string;
  email: string;
  onEdit?: () => void;
  onDelete?: () => void;
  onActivityChange?: (isActive: boolean) => void;
  onClick?: () => void;
}

export const UserCard: React.FC<UserCardProps> = ({
  width = 350,
  height = 150,
  onEdit,
  onDelete,
  userActive = true,
  image = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png",
  borderRadius = 20,
  avatarRadius = 100,
  userName = "user name",
  address = "address amman test",
  email = "email test",
  mobileNumber = "0798487414",
  onActivityChange = () => {},
  onClick = () => {},
}) => {
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const handleClickDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const { t } = useTranslation();

  const [isActive, setisActive] = React.useState(userActive);

  const PurpleSwitch = withStyles({
    switchBase: {
      color: "hsla(9, 100%, 50%, 1)",
      "&$checked": {
        color: colors.green[500],
      },
      "&$checked + $track": {
        backgroundColor: "white",
      },
      "& + $track": {
        backgroundColor: "white",
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const useStyles = makeStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      marginInline: "15px",
    },
    card: {
      display: "flex",
      flexDirection: "column",

      width: width,
      /*       height: height,
       */ backgroundColor: "white",
      borderRadius: borderRadius,
      /*       position: "absolute",
       */ marginTop: avatarRadius / 2,
    },
    content: {
      display: "flex",
      flexDirection: "column",
      marginTop: avatarRadius / 2 - (onEdit == undefined ? 0 : 45),
      paddingInline: 12,
    },
    avatar: {
      display: "flex",
      position: "absolute",
      borderRadius: avatarRadius,
      width: avatarRadius,
      height: avatarRadius,
      backgroundColor: "red",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
    },
    spacebetween: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      minHeight: 250,
    },
    img: {
      width: avatarRadius,
      height: avatarRadius,
    },
    editButton: {
      display: "flex",
      flexDirection: "row-reverse",
      paddingInline: 12,
    },
    deleteButton: {
      display: "flex",
      flexDirection: "row-reverse",
      paddingInline: 12,
      color: "red",
    },
    userName: {
      display: "flex",
      justifyContent: "center",
    },
    deviceName: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "red",
    },
    head: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    row: {
      display: "flex",
      justifyContent: "space-around",
    },
    footer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: 50,
      backgroundColor: isActive ? colors.green[500] : "hsla(9, 100%, 50%, 1)",
      color: "white",
      fontSize: 55,
    },
    battery: {
      transform: "rotate(90deg)",
    },
    switch: {
      width: 60,
      height: 39,
      margin: 5,
    },
  });
  const classes = useStyles();

  const handleUserActiveChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setisActive(event.target.checked);
    onActivityChange(isActive);
  };

  const editButton =
    onEdit == undefined ? (
      <div></div>
    ) : (
      <div className={classes.editButton}>
        <IconButton
          aria-label="edit"
          size="medium"
          color="primary"
          onClick={() => onEdit()}
        >
          <EditIcon fontSize="inherit" />
        </IconButton>
      </div>
    );

  const deleteButton =
    onDelete == undefined ? (
      <div></div>
    ) : (
      <div className={classes.deleteButton}>
        <IconButton
          aria-label="delete"
          size="medium"
          color="inherit"
          onClick={handleClickDeleteOpen}
        >
          <Clear fontSize="inherit" />
        </IconButton>
        <Dialog
          open={deleteOpen}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you welling to delete User?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Deleting the user is permenant and can not be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClose} color="primary">
              cancel
            </Button>
            <Button onClick={onDelete} autoFocus color="secondary">
              Clear Data
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );

  const userNameSection = (
    <div className={classes.userName}>
      <Typography variant="h6" gutterBottom color="primary">
        {userName}
      </Typography>
    </div>
  );

  const firstRow = (
    <div className={classes.row}>
      <IconedText text={address} iconColor="pink">
        <HomeIcon />
      </IconedText>
      <IconedText text={mobileNumber}>
        <CallIcon />
      </IconedText>
    </div>
  );

  const secondRow = (
    <div className={classes.row}>
      <IconedText text={email} iconColor="hsla(195, 86%, 46%, 1)">
        <EmailIcon />
      </IconedText>
      <IconedText text={mobileNumber}>
        <CallIcon />
      </IconedText>
    </div>
  );

  const footer =
    userActive == undefined ? (
      <div></div>
    ) : (
      <div className={classes.footer}>
        <PurpleSwitch checked={isActive} onChange={handleUserActiveChange} />
        <SizedBox width={8}></SizedBox>
        <Typography variant="h6" gutterBottom color="inherit">
          {isActive ? t("Active") : t("Suspended")}
        </Typography>
      </div>
    );

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <div className={classes.head}>
          {deleteButton}
          {editButton}
        </div>
        <div className={classes.spacebetween}>
          <div className={classes.content} onClick={() => onClick()}>
            {userNameSection}
            {firstRow}
            <SizedBox height={4}></SizedBox>
            {secondRow}
            <SizedBox height={14}></SizedBox>
          </div>
          {footer}
        </div>
      </Card>

      <div className={classes.avatar} onClick={() => onClick()}>
        <Avatar className={classes.img} alt="makaseb user" src={image} />
      </div>
    </div>
  );
};
