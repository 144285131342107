import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { config } from "../../config";
import { Logo } from "../core/logo/Logo";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  AuthenticationState,
  selectAuthenticate,
  authenticateWithEmailAndPassword,
} from "../../application/authentication/authentication_slice";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(2),
    backgroundColor: config.primaryColor,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  button: {
    color: "red",
  },
}));

export const SignIn = () => {
  const { t } = useTranslation();

  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://makaseb.org">
          Makaseb
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

  const [email, setEmail] = React.useState("");
  const [password, setPasssword] = React.useState("");

  const history = useHistory();
  const authentication = useSelector(selectAuthenticate);
  const dispatch = useDispatch();

  useEffect(() => {
    if (authentication == AuthenticationState.Authenticated) {
      history.push("/");
    }
  });

  const classes = useStyles();

  const ColorButton = withStyles((theme: Theme) => ({
    root: {
      color: "white",
      backgroundColor: config.primaryColor,
      "&:hover": {
        backgroundColor: config.primaryColor,
      },
    },
  }))(Button);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Logo backgroundColor={config.primaryColor} size="large"></Logo>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("Sign In")}
        </Typography>
        <ValidatorForm
          className={classes.form}
          onSubmit={(event) => {
            event.preventDefault();
            dispatch(authenticateWithEmailAndPassword(email, password));
          }}
        >
          <TextValidator
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("Email Address")}
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            validators={["required", "isEmail"]}
            errorMessages={["this field is required", "email is not valid"]}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <TextValidator
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="Password"
            label={t("Password")}
            type="password"
            id="password"
            value={password}
            validators={["required"]}
            errorMessages={["this field is required"]}
            onChange={(event) => {
              setPasssword(event.target.value);
            }}
            autoComplete="current-password"
          />
          <div className={classes.button}>
            <ColorButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={(event) => {}}
            >
              {t("Sign In")}
            </ColorButton>
          </div>
        </ValidatorForm>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};
