import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Add, Save } from "@material-ui/icons";
import * as React from "react";
import { SizedBox } from "../../core/components/sizedBox/sizedBox";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCreateDevice,
  addDevice,
  editDeviceName,
  editDeviceIMEI,
  save,
  saveEdit,
  loadDevice,
  editDeviceType,
  editTankDepth,
  deleteDevice,
} from "../../../application/create_device/create_device_slice";
import { DeviceType } from "../../../models/device";
import CreateDeviceForm from "../createDevice/components/createDeviceForm";
import { useHistory } from "react-router-dom";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IEditDeviceProps extends RouteComponentProps {}

const EditDevice: React.FunctionComponent<IEditDeviceProps> = (props) => {
  const { t } = useTranslation();

  const createDevicesState = useSelector(selectCreateDevice);
  const dispatch = useDispatch();

  const history = useHistory();
  const DeviceId = props.match.params["id"];

  React.useEffect(() => {
    dispatch(loadDevice(DeviceId));
  }, []);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      title: {
        display: "flex",
        margin: 18,
      },
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();
  const title = (
    <div className={classes.title}>
      <Typography variant="h5" color="primary">
        {t("Edit Device")}
      </Typography>
    </div>
  );

  const devices = (
    <CreateDeviceForm
      imei={createDevicesState.devices[0].imei}
      deviceName={createDevicesState.devices[0].name}
      depth={createDevicesState.devices[0].depth}
      isFloating={
        createDevicesState.devices[0].deviceType == DeviceType.Floating
      }
      key={DeviceId}
      onDeviceNameChange={(name) => {
        dispatch(editDeviceName({ name, index: 0 }));
      }}
      onIMEIChange={(imei) => {
        dispatch(editDeviceIMEI({ imei, index: 0 }));
      }}
      onDeviceTypeToggle={(toggle) => {
        var type = toggle ? DeviceType.Floating : DeviceType.Ultrasound;
        console.log("toggle")
        dispatch(editDeviceType({ type, index: 0 }));
      }}
      onDepthChange={(depth) => {
        dispatch(editTankDepth({ depth, index: 0 }));
      }}
      isDeleteable={false}
      onDelete={() => {
        dispatch(deleteDevice({ index: 0 }));
      }}
    ></CreateDeviceForm>
  );

  return (
    <div>
      {title}
      {devices}
      <SizedBox height={10}></SizedBox>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<Save />}
        size="large"
        onClick={() => {
          dispatch(saveEdit(history));
        }}
      >
        {t("Save")}
      </Button>
    </div>
  );
};

export default withRouter(EditDevice);
