import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DevicesRepository } from "../../domain/devices/devices_respo";
import { UserRepository } from "../../domain/devices/users_repo";
import type { RootState } from "../../store";
const userRepository = new UserRepository();
const deviceRepository = new DevicesRepository();

interface user {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  isEmailNotification: boolean;
  phoneNumber: string;
  alternativePhoneNumber: string;
  isSmsNotification: boolean;
  address: string;
  password: string;
}
interface ICreateUserState {
  user: user;
  devicesImeis: string[];
  selectedDevices: number[];
  deviceSearchText: string;
  isSaving: boolean;
  isSaveError: boolean;
  isSaved: boolean;
}

const emptyUser = {
  firstName: "",
  lastName: "",
  email: "",
  isEmailNotification: true,
  phoneNumber: "",
  alternativePhoneNumber: "",
  isSmsNotification: true,
  address: "",
  password: "",
};

const initialState: ICreateUserState = {
  user: { ...emptyUser },
  devicesImeis: [],
  selectedDevices: [],
  deviceSearchText: "",
  isSaved: false,
  isSaveError: false,
  isSaving: false,
};

export const createUserSlice = createSlice({
  name: "createUser",
  initialState,
  reducers: {
    clear: (state) => {
      state.user = emptyUser;
      state.devicesImeis = [];
      state.selectedDevices = [];
      state.deviceSearchText = "";
      state.isSaved = false;
      state.isSaveError = false;
      state.isSaving = false;
    },
    editFirstName: (state, action: PayloadAction<{ name: string }>) => {
      state.user.firstName = action.payload.name;
    },
    editLastName: (state, action: PayloadAction<{ name: string }>) => {
      state.user.lastName = action.payload.name;
    },
    editPhoneNumber: (state, action: PayloadAction<{ number: string }>) => {
      state.user.phoneNumber = action.payload.number;
    },
    editDeviceSearchtext: (state, action: PayloadAction<{ text: string }>) => {
      state.deviceSearchText = action.payload.text;
    },
    editPhoneAlternativeNumber: (
      state,
      action: PayloadAction<{ number: string }>
    ) => {
      state.user.alternativePhoneNumber = action.payload.number;
    },

    editEmailAddress: (state, action: PayloadAction<{ email: string }>) => {
      state.user.email = action.payload.email;
    },
    editAddress: (state, action: PayloadAction<{ address: string }>) => {
      state.user.address = action.payload.address;
    },
    editPassword: (state, action: PayloadAction<{ password: string }>) => {
      state.user.password = action.payload.password;
    },
    editSelectedDevices: (
      state,
      action: PayloadAction<{ devices: number[] }>
    ) => {
      state.selectedDevices = action.payload.devices;
    },
    editiSEmailNotification: (
      state,
      action: PayloadAction<{ isNotification: boolean }>
    ) => {
      state.user.isEmailNotification = action.payload.isNotification;
    },
    editiSSmsNotification: (
      state,
      action: PayloadAction<{ isNotification: boolean }>
    ) => {
      state.user.isSmsNotification = action.payload.isNotification;
    },
    addDeviceToList: (state, action: PayloadAction<{ imei: string }>) => {
      var list = [...state.devicesImeis];
      /*       list.push(action.payload.imei);
       */
      var newList = [...list, ...[action.payload.imei]];

      state.devicesImeis = newList;
    },
    removeDevices: (state) => {
      var selectedDevices = [...state.selectedDevices];
      var devicesImeis = [...state.devicesImeis];
      var count = 0;
      selectedDevices.forEach((index) => {
        console.log(index);
        devicesImeis.splice(index - count, 1);
        count += 1;
      });
      console.log(devicesImeis);
      state.devicesImeis = devicesImeis;
    },

    setUser: (state, action: PayloadAction<{ user: user }>) => {
      state.user = action.payload.user;
    },

    setSaved: (state) => {
      var newState = { ...state };
      newState.isSaved = true;
      newState.isSaving = false;
      newState.isSaveError = false;
      newState.user = { ...emptyUser };
      newState.devicesImeis = [];
      return newState;
    },
    setError: (state) => {
      var newState = { ...state };
      newState.isSaveError = true;
      newState.isSaving = false;
      return newState;
    },
    setSaving: (state) => {
      var newState = { ...state };
      newState.isSaving = true;
      return newState;
    },
  },
});

export const {
  clear,
  editFirstName,
  editLastName,
  editEmailAddress,
  editSelectedDevices,
  editiSEmailNotification,
  editiSSmsNotification,
  editPhoneNumber,
  editPhoneAlternativeNumber,
  editPassword,
  addDeviceToList,
  editAddress,
  removeDevices,
  editDeviceSearchtext,
  setSaved,
  setError,
  setSaving,
  setUser,
} = createUserSlice.actions;

export const save = () => async (dispatch, getState) => {
  const state: ICreateUserState = getState().createUser;
  if (
    state.user.firstName == "" ||
    state.user.lastName == "" ||
    state.user.email == "" ||
    state.user.password == "" ||
    state.user.phoneNumber == "" ||
    state.user.address == ""
  ) {
    alert(
      "first name, Last name, email, address, phone number and password should not be empty "
    );
    return;
  }
  try {
    dispatch(setSaving());
    await userRepository.createUser({
      user: state.user,
      devices: state.devicesImeis,
    });
    dispatch(setSaved());
  } catch (error) {
    console.log(error);
    dispatch(setError());
  }
};

export const edit = (history) => async (dispatch, getState) => {
  const state: ICreateUserState = getState().createUser;
  if (
    state.user.firstName == "" ||
    state.user.lastName == "" ||
    state.user.email == "" ||
    state.user.phoneNumber == "" ||
    state.user.address == ""
  ) {
    alert(
      "first name, Last name, email, address, phone number should not be empty "
    );
    return;
  }
  try {
    dispatch(setSaving());
    await userRepository.editUser({
      user: state.user,
      devices: state.devicesImeis,
    });
    dispatch(setSaved());
    history.push("/user");
  } catch (error) {
    console.log(error);
    dispatch(setError());
  }
};

export const addDevice = (imei: string) => async (dispatch, getState) => {
  const state: ICreateUserState = getState().createUser;
  if (state.devicesImeis.includes(imei)) {
    return;
  }
  try {
    var device = await deviceRepository.getDeviceByImei(imei);
    if (device) {
      dispatch(addDeviceToList({ imei: imei }));
    }
  } catch (error) {
    alert("Device not found");
  }
};

export const loadUser = (id: string) => async (dispatch, getState) => {
  try {
    var loadedUser = await userRepository.getUser(id);
    console.log(loadedUser);
    console.log(loadedUser.isSmsNotification);

    dispatch(
      setUser({
        user: {
          id: loadedUser.id,
          firstName: loadedUser.firstName!,
          lastName: loadedUser.lastName!,
          email: loadedUser.email!,
          isEmailNotification: loadedUser.isEmailNotification!,
          phoneNumber: loadedUser.phoneNumber!,
          alternativePhoneNumber: loadedUser.alternativePhoneNumber!,
          isSmsNotification: loadedUser.isSmsNotification!,
          address: loadedUser.address!,
          password: "",
        },
      })
    );
    loadedUser.devices?.forEach((device) => {
      dispatch(addDeviceToList({ imei: device.imei! }));
    });
  } catch (error) {
    alert("error loading user");
  }
};

export const removeUserDevices = () => async (dispatch, getState) => {
  const state: ICreateUserState = getState().createUser;

  var selectedDevices = [...state.selectedDevices];
  var devicesImeis = [...state.devicesImeis];

  try {
    await Promise.all(
      selectedDevices.map(async (selectedDevice) => {
        var selectedImei = devicesImeis[selectedDevice];
        await userRepository.removeDeviceFromUser(state.user.id!, selectedImei);
      })
    );
    dispatch(removeDevices());
  } catch (error) {
    alert("error removing devices");
  }
};

export const selectCreateUser = (state: RootState) => state.createUser;

export default createUserSlice.reducer;
