import {
  Card,
  colors,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { config } from "../../../config";
import InfiniteScroll from "react-infinite-scroll-component";

import { UserCard } from "../../core/components/userCard/UserCard";
import { SearchField } from "../../core/components/searchField/SearchField";
import { useSelector, useDispatch } from "react-redux";
import {
  selectLoadUsers,
  load,
  activateUser,
  deactivateUser,
  deleteUser,
  updateSearchText,
} from "../../../application/load_users/load_users.slice";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface IManagedUsersProps {}

export const ManageUsers: React.FunctionComponent<IManagedUsersProps> = (
  props
) => {
  const devicesState = useSelector(selectLoadUsers);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(load());
  }, []);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        color: colors.blue[200],
      },
      sortBar: {
        background: config.appBarColor,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        padding: theme.spacing(2.0),
      },
      searchField: {
        display: "flex",
        margin: theme.spacing(3),
        justifyContent: "center",
      },
      cards: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        flexWrap: "wrap",
        margin: 20,
        padding: 20,
      },
      title: {
        display: "flex",
        margin: 18,
      },
      card: {
        marginBottom: 30,
      },
    })
  );

  const classes = useStyles();

  const title = (
    <div className={classes.title}>
      <Typography variant="h5" color="primary">
        {t("Manage Users")}
      </Typography>
    </div>
  );

  const userCards = devicesState.users.map((user, index) => {
    return (
      <div className={classes.card} key={user.email}>
        <UserCard
          onDelete={() => {
            dispatch(deleteUser(user.id));
          }}
          onClick={() => {
            history.push(`/user/${user.id}/devices`);
          }}
          userActive={user.isActive}
          userName={user.firstName + " " + user.lastName}
          address={user.address}
          mobileNumber={user.phoneNumber}
          email={user.email}
          onActivityChange={(isActive) => {
            !isActive
              ? dispatch(activateUser(index))
              : dispatch(deactivateUser(index));
          }}
          onEdit={() => {
            history.push(`/user/${user.id}/edit`);
          }}
        ></UserCard>
      </div>
    );
  });

  const searchBar = (
    <div className={classes.searchField}>
      <SearchField
        placeHolder={t("Search User....")}
        onChange={(text) => {
          dispatch(updateSearchText(text));
        }}
      ></SearchField>
    </div>
  );

  const body = (
    /* devicesState.isLoading ? (
    <p>loading...</p>
  ) : devicesState.isErrorLoading ? (
    <p>error...</p>
  ) : */
    <InfiniteScroll
      className={classes.cards}
      dataLength={userCards.length}
      next={() => {}}
      hasMore={false}
      loader={<h4>Loading...</h4>}
    >
      {userCards}
    </InfiniteScroll>
  );

  return (
    <div>
      {title}
      {searchBar}
      <div>{body}</div>
    </div>
  );
};
