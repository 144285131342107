import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DevicesRepository } from "../../domain/devices/devices_respo";
import { Device, DeviceType } from "../../models/device";
import type { RootState } from "../../store";
const uuidv4 = require("uuid/v4");

const repository = new DevicesRepository();

interface device {
  id: string;
  name: string;
  tankLevel: number;
  imei: string;
  address: string;
  depth: string;
  deviceType: DeviceType;
  lastUpdated: string;
  batteryLevel: number;
}

interface ICreateDeviceState {
  devices: device[];
  isSaving: boolean;
  isSaveError: boolean;
  isSaved: boolean;
}

const initialState: ICreateDeviceState = {
  devices: [
    {
      id: uuidv4(),
      name: "",
      tankLevel: 0,
      imei: "",
      address: "",
      depth: "",
      deviceType: DeviceType.Ultrasound,
      lastUpdated: new Date().toTimeString(),
      batteryLevel: 0,
    },
  ],
  isSaved: false,
  isSaveError: false,
  isSaving: false,
};

export const createDeviceSlice = createSlice({
  name: "createDevices",
  initialState,
  reducers: {
    addDevice: (state) => {
      var newState = { ...state };
      newState.devices.push({
        id: uuidv4(),
        name: "",
        tankLevel: 0,
        imei: "",
        address: "",
        depth: "",
        deviceType: DeviceType.Ultrasound,
        lastUpdated: new Date().toTimeString(),
        batteryLevel: 0,
      });
      /*return newState;*/
    },
    setDevice: (state, action: PayloadAction<{ device: device }>) => {
      var newState = { ...state };
      newState.devices[0] = action.payload.device;
    },
    editDeviceName: (
      state,
      action: PayloadAction<{ name: string; index: number }>
    ) => {
      var newState = { ...state };
      var device = newState.devices[action.payload.index];
      device.name = action.payload.name;
      newState.devices[action.payload.index] = device;
      /*       return newState;
       */
    },
    editDeviceIMEI: (
      state,
      action: PayloadAction<{ imei: string; index: number }>
    ) => {
      var newState = { ...state };
      var device = state.devices[action.payload.index];
      device.imei = action.payload.imei;
      newState.devices[action.payload.index] = device;

      /*       return newState;
       */
    },
    editTankDepth: (
      state,
      action: PayloadAction<{ depth: string; index: number }>
    ) => {
      var newState = { ...state };
      var device = state.devices[action.payload.index];
      device.depth = action.payload.depth;
      newState.devices[action.payload.index] = device;

      /*       return newState;
       */
    },
    deleteDevice: (state, action: PayloadAction<{ index: number }>) => {
      console.log("okk");
      if (action.payload.index <= 0) {
        return;
      }
      var newState = { ...state };
      newState.devices.splice(action.payload.index, 1);
    },

    editDeviceType: (
      state,
      action: PayloadAction<{ type: DeviceType; index: number }>
    ) => {
      console.log("edit device type");
      console.log(action);
      console.log(state);
      var newState = { ...state };
      var device = newState.devices[action.payload.index];
      device.deviceType = action.payload.type;
      newState.devices[action.payload.index] = device;
      /*       return newState;
       */
    },
    setSaved: (state) => {
      var newState = { ...state };
      newState.isSaved = true;
      newState.isSaving = false;
      newState.isSaveError = false;
      newState.devices = [
        {
          id: uuidv4(),
          name: "",
          tankLevel: 0,
          imei: "",
          address: "",
          depth: "",
          deviceType: DeviceType.Ultrasound,
          lastUpdated: new Date().toTimeString(),
          batteryLevel: 0,
        },
      ];
      return newState;
    },
    setError: (state) => {
      var newState = { ...state };
      newState.isSaveError = true;
      newState.isSaving = false;

      return newState;
    },
    setSaving: (state) => {
      var newState = { ...state };
      newState.isSaving = true;
      return newState;
    },
  },
});

export const {
  addDevice,
  editDeviceName,
  editDeviceIMEI,
  editDeviceType,
  editTankDepth,
  deleteDevice,
  setDevice,
  setSaved,
  setError,
  setSaving,
} = createDeviceSlice.actions;

export const save = () => async (dispatch, getState) => {
  console.log("save");

  const state: ICreateDeviceState = getState().createDevice;
  try {
    var isValid = true;
    state.devices.forEach((device) => {
      if (device.imei == "" || device.name == "" || device.depth == "") {
        isValid = false;
      }
    });

    if (!isValid) {
      console.log("empty values");
      alert("imei , name , and depth should not be empty values");
      return;
    }

    dispatch(setSaving());
    await Promise.all(
      state.devices.map(async (device) => {
        var newDevice: Device = new Device(
          device.id,
          device.name,
          device.tankLevel,
          device.imei,
          device.address,
          device.deviceType,
          device.lastUpdated,
          device.batteryLevel,
          undefined,
          device.depth
        );
        await repository.createDevice({ device: newDevice });
      })
    );
    dispatch(setSaved());
  } catch (error) {
    console.log(error);
    dispatch(setError());
  }
};

export const saveEdit = (history) => async (dispatch, getState) => {
  const state: ICreateDeviceState = getState().createDevice;
  console.log("state before save");
  console.log(state);
  try {
    var isValid = true;
    state.devices.forEach((device) => {
      if (device.imei == "" || device.name == "" || device.depth == "") {
        isValid = false;
      }
    });

    if (!isValid) {
      console.log("empty values");
      alert("imei , name , and depth should not be empty values");
      return;
    }

    dispatch(setSaving());
    await Promise.all(
      state.devices.map(async (device) => {
        var newDevice: Device = new Device(
          device.id,
          device.name,
          device.tankLevel,
          device.imei,
          device.address,
          device.deviceType,
          device.lastUpdated,
          device.batteryLevel,
          undefined,
          device.depth
        );
        await repository.editDevice({ device: newDevice });
      })
    );
    dispatch(setSaved());
    history.push("/");
  } catch (error) {
    console.log(error);
    dispatch(setError());
  }
};

export const loadDevice = (id: string) => async (dispatch, getState) => {
  try {
    const device: Device = await repository.getDevice(id);
    dispatch(
      setDevice({
        device: {
          id: device.id!,
          name: device.name!,
          tankLevel: device.tankLevel!,
          batteryLevel: device.batteryLevel!,
          depth: device.depth!,
          imei: device.imei!,
          address: device.address! ?? "",
          lastUpdated: device.updatedAt!,
          deviceType: device.type!,
        },
      })
    );
  } catch (error) {
    alert("error loading device");
  }
};

export const selectCreateDevice = (state: RootState) => state.createDevice;

export default createDeviceSlice.reducer;
