import React from "react";
import "./App.css";
import "@fontsource/roboto";
import { Layout } from "./presentation/core/layOut/Layout";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { categoriesRoutes, Rout } from "./routes";
import { SignIn } from "./presentation/login/login";
import { ProtectedRoute } from "./core/util/protectedRoute/protectedRoute";
import { useEffect } from "react";
import { autoSignIn } from "./application/authentication/authentication_slice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import withRoot from "./i18n/withRoot";
import {
  createStyles,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  useTheme,
} from "@material-ui/core";

function App() {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      selectLanguage: {
        color: "english",
        fontWeight: "bold",
        borderStyle: "none",
        borderWidth: 0,
      },
      icon: {
        fill: "english",
      },
      changeLang: {
        display: "flex",
        marginInline: 20,
        marginTop:"-10px"
      },
    })
  );

  const classes = useStyles();

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  document.body.dir = i18n.dir();
  theme.direction = i18n.dir();

  const changeLanguage = (lng) => {
    console.log(lng);
    i18n.changeLanguage(lng);
    document.body.dir = i18n.dir();
    theme.direction = i18n.dir();
  };

  const changeLanguageComponent = (
    <Select
      inputProps={{
        classes: {
          icon: classes.icon,
        },
      }}
      disableUnderline
      className={classes.selectLanguage}
      labelId="demo-simple-select-outlined-label"
      id="demo-simple-select-outlined"
      value={i18n.language == "ar" ? "ar" : "en"}
      onChange={(val) => changeLanguage(val.target.value)}
      label="Language"
    >
      <MenuItem value={"en"}>English</MenuItem>
      <MenuItem value={"ar"}>عربي</MenuItem>
    </Select>
  );

  useEffect(() => {
    dispatch(autoSignIn());
    /* autoAuth(); */
  });

  var pages: Rout[] = [];
  categoriesRoutes.forEach((cat) => {
    cat.routes.forEach((rout) => {
      pages.push(rout);
    });
  });

  const routes = pages.map((page) => {
    return (
      <ProtectedRoute path={page.path} exact key={page.name}>
        <page.component />
      </ProtectedRoute>
    );
  });

  return (
    <div className="App">
      <Router>
        <Layout>
          <Switch>
            {routes}
            <Route path="/login" exact>
              <div className={classes.changeLang}>
                {changeLanguageComponent}
              </div>
              <SignIn />
            </Route>
          </Switch>
        </Layout>
      </Router>
    </div>
  );
}

export default withRoot(App);
