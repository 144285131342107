import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import {
  AppBar,
  IconButton,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useSelector } from "react-redux";
import {
  AuthenticationState,
  selectAuthenticate,
} from "../../../application/authentication/authentication_slice";
import { Logo } from "../logo/Logo";
import { useTranslation } from "react-i18next";

export interface MyAppBarProps {
  backgroundColor: string;
  width: number;
  handleDrawerToggle: VoidFunction;
}

export const MyAppBar: React.FC<MyAppBarProps> = ({
  backgroundColor,
  width,
  handleDrawerToggle,
}) => {
  const authentication = useSelector(selectAuthenticate);
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const changeLanguage = (lng) => {
    console.log(lng);
    i18n.changeLanguage(lng);
    document.body.dir = i18n.dir();
    theme.direction = i18n.dir();
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appBar: {
        [theme.breakpoints.up("sm")]: {
          width: `calc(100% - ${width}px)`,
          marginLeft: width,
        },
        backgroundColor: backgroundColor,
      },
      menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
          display: "none",
        },
      },
      center: {
        margin: "auto",
      },
      selectLanguage: {
        color: "white",
        fontWeight: "bold",
        borderStyle: "none",
        borderWidth: 0,
      },
      icon: {
        fill: "white",
      },
      toolbar: theme.mixins.toolbar,
    })
  );

  const classes = useStyles();

  const changeLanguageComponent = (
    <Select
      inputProps={{
        classes: {
          icon: classes.icon,
        },
      }}
      disableUnderline
      className={classes.selectLanguage}
      labelId="demo-simple-select-outlined-label"
      id="demo-simple-select-outlined"
      value={i18n.language == "ar" ? "ar" : "en"}
      onChange={(val) => changeLanguage(val.target.value)}
      label="Language"
    >
      <MenuItem value={"en"}>English</MenuItem>
      <MenuItem value={"ar"}>عربي</MenuItem>
    </Select>
  );

  const content =
    authentication == AuthenticationState.Authenticated ? (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.center}>
            <Logo size={"small"}></Logo>
          </div>
          {changeLanguageComponent}
          {/* <Typography variant="h6" noWrap>
            Makaseb
          </Typography> */}
        </Toolbar>
      </AppBar>
    ) : (
      <div></div>
    );

  return <div>{content}</div>;
};
